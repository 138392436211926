import {Card, CardBody, CardFooter, CardHeader, Button, ScrollShadow} from "@nextui-org/react";
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure} from "@nextui-org/react";
import SharedServiceInstance from "@/services/shared-service";
import {LEVEL_CLERGY_MAP} from "@/configuration/static-data-provider";
import {LiaBirthdayCakeSolid, LiaChevronCircleLeftSolid, LiaChevronCircleRightSolid} from "react-icons/lia";
import {GiHeartWings, GiPartyPopper} from "react-icons/gi";
import {useEffect, useReducer, useState} from "react";
import {getDailyEvents, getGospel, getNewsByParableCode} from "@/services/home-service";
import sharedService from "@/services/shared-service";
import {MdEvent} from "react-icons/md";
import Link from "next/link";
import {TbGrave} from "react-icons/tb";
import SharedService from "@/services/shared-service";

export default function EventsDailyNotification() {
    const {isOpen, onOpen, onClose} = useDisclosure();
    const [detail, setDetail] = useState({
        currentDate: SharedServiceInstance.moment(),
        today: null,
        description: null,
        birth: null,
        priest: null,
        saint: null,
        masses: null,
        scheduleEvents: null
    });
    const [quotes, setQuotes] = useState([]);
    const [relativeNew, setRelativeNew] = useState([]);
    let regex = /[1-9a-zA-ZĐđ]+ \d+,?(\d+)?.?(\d+)?(\w+)?-?(\d+)?,?(\d+)?.?(\d+)?(\w+)?-?(\d+),?(\d+)?.?(\d+)(\w)?(-\d+)?/g;

    async function onClickNextDay() {
        detail.currentDate = detail.currentDate.add(1, "day");
        await renderComponentByDate();
    }

    async function onClickPreviousDay() {
        detail.currentDate = detail.currentDate.add(-1, "day");
        await renderComponentByDate();
    }

    useEffect(() => {
        renderComponentByDate();
    }, [])

    async function renderComponentByDate() {
        let dailyEvents = await getDailyEvents(detail.currentDate);
        setDetail({
            ...detail,
            currentDate: detail.currentDate,
            today: detail.currentDate.format("DD-MM-YYYY"),
            rip: dailyEvents.rip.map(e => `${LEVEL_CLERGY_MAP[e.level]} ${e.entitySTName} ${e.entityName}`),
            birth: dailyEvents.birth.map(e => `${LEVEL_CLERGY_MAP[e.level]} ${e.entitySTName} ${e.entityName}`),
            priest: dailyEvents.priest.map(e => `${LEVEL_CLERGY_MAP[e.level]} ${e.entitySTName} ${e.entityName}`),
            saint: dailyEvents.saint.map(e => `${LEVEL_CLERGY_MAP[e.level]} ${e.entitySTName} ${e.entityName}`),
            masses: dailyEvents.masses,
            description: dailyEvents.masses.length > 0 ? dailyEvents.masses[0].description : null,
            scheduleEvents: dailyEvents.scheduleEvents
        });
    }

    async function openCode(code) {
        let quotes = await getGospel(code);
        let news = await getNewsByParableCode(code);
        setQuotes(quotes);
        setRelativeNew(news.results);
        onOpen();
    }

    let gospel;
    let baiDoc;
    return (
        <div className="w-full h-full">
            <Card
                isBlurred
                shadow="sm"
                className="pt-2 w-full h-full border-none md:rounded-[5px] rounded-none !bg-white/85 !bg-cover !bg-[url('/webp/chanh-toa.webp')] !bg-blend-lighten">
                <CardHeader className="justify-center">
                    <h1 className="text-2xl font-bold text-primary">SỰ KIỆN</h1>
                </CardHeader>
                <CardBody className="h-[275px]">
                    <ScrollShadow hideScrollBar className="w-full h-[270px]">
                        {
                            detail.masses && detail.masses.length <= 0 && detail.scheduleEvents.length <= 0 && detail.birth.length <= 0 && detail.priest.length <= 0 && detail.saint.length <= 0 &&
                            <h2 className="text-lg font-semibold text-blue-500">Không có dữ liệu tin mừng</h2>
                        }
                        {
                            detail.masses && detail.masses.map((item, index) => {
                                if (index === 0) {
                                    gospel = item.baiDoc.match(regex);
                                    baiDoc = item.baiDoc;
                                    if (item.link) {
                                        return (
                                            <div key={index}>
                                                <Link className="mt-5 text-xl font-semibold text-primary"
                                                      dangerouslySetInnerHTML={{__html: `${item.tenLe}`}}
                                                      href={item.linkType === 'articles' ? SharedService.getPostPath(item) : item.link}
                                                      target='_blank'></Link>
                                            </div>
                                        );
                                    } else {
                                        return (
                                            <div key={index}>
                                                <h2 className="mt-5 text-xl font-semibold text-primary"
                                                    dangerouslySetInnerHTML={{__html: `${item.tenLe}`}}></h2>
                                            </div>
                                        );
                                    }
                                } else {
                                    return (
                                        <div key={index}>
                                            <h2 className="mt-5 text-lg font-semibold text-black"
                                                dangerouslySetInnerHTML={{__html: `${item.tenLe}`}}></h2>
                                        </div>
                                    );
                                }
                            })
                        }
                        {
                            detail.description && <h1 className="italic mt-5 text-primary">{detail.description}</h1>
                        }
                        {
                            gospel ? <h3 className="mt-5 text-medium"><span
                                    className="text-primary">Bài Đọc:</span> {gospel.map((g, index) => {
                                    if (!sharedService.isNullOrEmpty(g) && index !== gospel.length - 1) {
                                        return (
                                            <span key={index}
                                                  className="text-link hover:text-amber-800/70 hover:cursor-pointer"
                                                  onClick={() => openCode(g.trim())}>{g}; </span>
                                        );
                                    } else if (!sharedService.isNullOrEmpty(g)) {
                                        return (
                                            <span key={index}
                                                  className="text-link hover:text-amber-800/70 hover:cursor-pointer"
                                                  onClick={() => openCode(g.trim())}>{g}.</span>
                                        );
                                    }
                                })}
                                </h3> :
                                <h3 className="mt-5 text-medium text-amber-800"><span
                                    className="text-primary">Bài Đọc: </span><span
                                    dangerouslySetInnerHTML={{__html: `${baiDoc}`}}></span></h3>
                        }
                        {
                            detail.rip && detail.rip.length > 0 &&
                            <div className="mt-5">
                             <span className="flex text-purple-900 font-semibold items-center gap-1"><TbGrave
                                 className="h-[16px] w-[16px]"/>Ngày mất:</span>
                                <span className="inline-block ml-5 text-purple-700">{detail.rip.join(', ')}</span>
                            </div>
                        }
                        {
                            detail.birth && detail.birth.length > 0 &&
                            <div className="mt-5">
                             <span className="flex text-red-500 font-semibold items-center gap-1"><LiaBirthdayCakeSolid
                                 className="h-[16px] w-[16px]"/>Mừng sinh nhật:</span>
                                <span className="inline-block ml-5 text-blue-400">{detail.birth.join(', ')}</span>
                            </div>
                        }
                        {
                            detail.priest && detail.priest.length > 0 &&
                            <div className="mt-5">
                    <span className="flex text-red-500 font-semibold items-center gap-1"><GiPartyPopper
                        className="h-[16px] w-[16px]"/>Kỷ niệm thụ phong Linh Mục:</span>
                                <span className="inline-block ml-5 text-blue-400">{detail.priest.join(', ')}</span>
                            </div>
                        }
                        {
                            detail.saint && detail.saint.length > 0 &&
                            <div className="mt-5">
                    <span className="flex text-red-500 font-semibold items-center gap-1"><GiHeartWings
                        className="h-[16px] w-[16px]"/>Mừng bổn mạng:</span>
                                <span className="inline-block ml-5 text-blue-400">{detail.saint.join(', ')}</span>
                            </div>
                        }
                        {
                            detail.scheduleEvents && detail.scheduleEvents.length > 0 &&
                            <div className="mt-5">
                               <span className="flex text-red-500 font-semibold items-center gap-1"><MdEvent
                                   className="h-[16px] w-[16px]"/>Sự kiện:</span>
                                {detail.scheduleEvents && detail.scheduleEvents.map((e, index) => {
                                    return (
                                        <div key={index}>
                                        <span>{e.link && e.link.length > 0 ?
                                            <Link className={"font-semibold text-link"} href={e.link}>{e.name}</Link> :
                                            <span
                                                className={"font-semibold"}>{e.name}</span>} - {e.entityType === 'organization' ?
                                            <Link
                                                className="text-link"
                                                href={`/nha-tho/${e.entityID}`}>{e.quotation}
                                                {
                                                    e.code !== 'chau_thanh_the' &&
                                                    <span> (Từ {sharedService.formatISODate(e.startDate, "HH:mm")} ngày {sharedService.formatISODate(e.startDate, "DD/MM/YYYY")} đến {sharedService.formatISODate(e.endDate, "HH:mm")} ngày {sharedService.formatISODate(e.endDate, "DD/MM/YYYY")})</span>}</Link> :
                                            <span>{e.quotation}
                                                {
                                                    e.code !== 'chau_thanh_the' &&
                                                    <span> (Từ {sharedService.formatISODate(e.startDate, "HH:mm")} ngày {sharedService.formatISODate(e.startDate, "DD/MM/YYYY")} đến {sharedService.formatISODate(e.endDate, "HH:mm")} ngày {sharedService.formatISODate(e.endDate, "DD/MM/YYYY")})</span>}</span>}</span>
                                        </div>
                                    )
                                })}
                            </div>
                        }
                    </ScrollShadow>
                </CardBody>
                <CardFooter className="justify-center">
                    <div className="flex justify-between items-center">
                        <Button onClick={onClickPreviousDay} className="bg-transparent" isIconOnly size="sm"
                                aria-label="Previous">
                            <LiaChevronCircleLeftSolid className="h-[32px] w-[32px]"/>
                        </Button>
                        {detail.today && <div className="mx-10">{detail.today}</div>}
                        <Button onClick={onClickNextDay} className="bg-transparent" isIconOnly size="sm"
                                aria-label="Next">
                            <LiaChevronCircleRightSolid className="h-[32px] w-[32px]"/>
                        </Button>
                    </div>
                </CardFooter>
            </Card>

            <Modal className="p-10" size="xl" isOpen={isOpen} onClose={onClose}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">Tin Mừng</ModalHeader>
                            <ScrollShadow hideScrollBar={true} className="w-full h-[32rem]">
                                <ModalBody className="inline">
                                    <>
                                        {
                                            quotes.map(q => {
                                                return (
                                                    <span key={q.sentenceNo} className="text-medium inline"><p
                                                        className="align-text-top italic text-[10px] inline">[{q.sentenceNo}]</p>{q.content}</span>
                                                );
                                            })
                                        }
                                        {relativeNew && relativeNew.length > 0 &&
                                            <div>
                                                <span className="font-bold text-primary">Bài liên quan</span>
                                                {relativeNew.map((n, index) => {
                                                        return (<div key={n.id} className="text-link">
                                                            <Link target="_blank" key={n.id}
                                                                  href={`/bai-viet/${n.categoryCode ? n.categoryCode : 'post'}/${n.id}`}>{`${index + 1}. ${n.title}`}</Link>
                                                        </div>);
                                                    })}
                                            </div>
                                        }
                                    </>
                                </ModalBody>
                            </ScrollShadow>
                            <ModalFooter>
                                <Button variant="light" onPress={onClose}>
                                    Đóng
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </div>
    );
}